import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  topBar: {
    padding: theme.spacing(1, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  meta: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
    overflowX: 'auto',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    minHeight: 200,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  fileLink: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 100,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
  },
  fileName: {
    marginTop: theme.spacing(1),
    fontFamily: 'Open Sans',
  },
  inputRoot: {
    height: 30,
    borderRadius: 5,
    marginTop: 18,
    backgroundColor: fade(theme.palette.common.white, 0.05),
    width: '100%',
    color: fade(theme.palette.common.white, 0.25),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: '100%',
    fontFamily: 'Open Sans',
    fontWeight: 300,
  },
  fileLinkTitle: {
    fontSize: 14,
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  linkButton: {
    color: fade(theme.palette.common.white, 0.25),
    backgroundColor: fade(theme.palette.common.white, 0.05),
    borderRadius: 5,
    marginLeft: 10,
    padding: 10,
    marginTop: 18,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  column: {
    display: 'grid',
  },
  container: {
    width: '100%',
  },
}));

export default useStyles;
