import PropTypes from 'prop-types';

const userShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.oneOf(['user', 'admin']),
});

export default userShape;
