import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { fade } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import getNextPrevFile from '../../apollo/queries/getNextPrevFile.graphql';
import Loading from '../../common/Loading';
import useTranslation from '../common/useTranslation';
import { generateFilePath } from '../../common/routes';
import historyShape from '../../common/shapes/historyShape';

const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    color: fade(theme.palette.common.white, 0.25),
    '& .inactive': {
      visibility: 'hidden',
      cursor: 'default',
    },
    '& span': {
      cursor: 'pointer',
      display: 'flex',
    },
  },
  left: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function PrevNextFile({ currentFileId, projectName, history }) {
  const classes = useStyles();
  const translate = useTranslation();
  const { loading, data } = useQuery(getNextPrevFile, {
    variables: {
      projectName,
      fileId: currentFileId,
    },
    fetchPolicy: 'network-only',
  });

  const videos = get(data, 'getNextPrevFile', {});

  const goToFile = fileId => {
    if (fileId) {
      history.push(generateFilePath(projectName, fileId));
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div
            className={`${classes.left} ${videos.prevFileId ? '' : 'inactive'}`}
          >
            <span onClick={() => goToFile(videos.prevFileId)}>
              <ChevronLeftIcon />
              {translate('previousVideo')}
            </span>
          </div>
          <div
            className={`${classes.right} ${
              videos.nextFileId ? '' : 'inactive'
            }`}
          >
            <span onClick={() => goToFile(videos.nextFileId)}>
              {translate('nextVideo')}
              <ChevronRightIcon />
            </span>
          </div>
        </Fragment>
      )}
    </div>
  );
}

PrevNextFile.propTypes = {
  currentFileId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  history: historyShape.isRequired,
};
