import React, { useState } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import getProjectFolders from '../../apollo/queries/getProjectFolders.graphql';
import Loading from '../../common/Loading';
import createFoldersTree from '../../common/utils/createFoldersTree';
import historyShape from '../../common/shapes/historyShape';
import { generateProjectPath } from '../../common/routes';
import useTranslation from '../common/useTranslation';

export default function FolderTree({
  projectName,
  handleFolderChange,
  initialPath,
  history,
  inSearchMode,
}) {
  const [activeNode, setActiveNode] = useState(null);
  const [tree, setTree] = useState(null);
  const translate = useTranslation();

  const handleNodeChange = (path, nodeName) => {
    handleFolderChange(`${path}${nodeName},`);
    setActiveNode({
      path,
      name: nodeName,
    });
  };

  const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(1),
      maxWidth: 400,
    },
    treeItem: {
      '& div': {
        backgroundColor: 'transparent !important',
      },
    },
    active: {
      color: theme.palette.secondary.main,
    },
    treePaper: {
      paddingTop: 0,
      maxHeight: '60vh',
      overflow: 'auto',
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(3),
      borderRadius: 5,
      boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
    },
    treeHeader: {
      borderRadius: 5,
      marginLeft: -theme.spacing(3),
      marginTop: -theme.spacing(3),
      marginRight: -theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.light,
      fontSize: '16px',
    },
  }));
  // TODO: handle error
  const { loading } = useQuery(getProjectFolders, {
    variables: {
      projectName,
    },

    onCompleted: data => {
      const currentTree = createFoldersTree(
        data.getProjectFolders,
        initialPath,
      );
      if (initialPath) {
        history.push(generateProjectPath(projectName));
      }
      if (!inSearchMode) {
        handleNodeChange(
          currentTree.latestElementPath,
          currentTree.latestNodeName,
        );
      }
      setActiveNode({
        path: currentTree.latestElementPath,
        name: currentTree.latestNodeName,
      });
      setTree(currentTree);
    },
  });

  const classes = useStyles();

  const renderNodes = nodes => {
    return nodes.map(node => {
      const active =
        node.path === activeNode.path && node.name === activeNode.name;
      return (
        <TreeItem
          className={`${classes.treeItem} ${
            !inSearchMode && active ? classes.active : ''
          }`}
          nodeId={node._id}
          label={node.name}
          key={node._id}
          onClick={
            node.children.length
              ? () => {}
              : () => handleNodeChange(node.path, node.name)
          }
        >
          {node.children && node.children.length
            ? renderNodes(node.children)
            : null}
        </TreeItem>
      );
    });
  };
  if (loading || !tree) return <Loading />;

  return tree.children.length ? (
    <Grid item md={4} xs={12}>
      <Paper className={`${classes.treePaper} ${classes.paper}`}>
        <div className={classes.treeHeader}>{translate('resourceManager')}</div>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={tree.defaultExpanded}
        >
          {renderNodes(tree.children)}
        </TreeView>
      </Paper>
    </Grid>
  ) : null;
}

FolderTree.propTypes = {
  projectName: PropTypes.string.isRequired,
  handleFolderChange: PropTypes.func.isRequired,
  initialPath: PropTypes.string,
  history: historyShape.isRequired,
  inSearchMode: PropTypes.bool,
};

FolderTree.defaultProps = {
  initialPath: null,
  inSearchMode: false,
};
