import React from 'react';
import FaceIcon from '@material-ui/icons/Face';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useMutation, useQuery } from '@apollo/react-hooks';
import userShape from '../../common/shapes/userShape';
import useTranslation from '../common/useTranslation';
import getUsersQuery from '../../apollo/queries/getUsers.graphql';
import Loading from '../../common/Loading';
import addUserToProjectMutation from '../../apollo/mutations/addUserToProject.graphql';
import getProjectByNameQuery from '../../apollo/queries/getProjectByName.graphql';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'block',
    padding: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const useAutoCompleteStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    '& .MuiFormLabel-root': {
      color: theme.palette.common.white,
    },
  },
  inputRoot: {
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
  },
  groupLabel: {
    color: theme.palette.common.white,
  },
  listbox: {
    backgroundColor: theme.palette.primary.light,
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
}));

export default function UserList({ users, handleDelete, projectName }) {
  const classes = useStyles();
  const autocompleteClasses = useAutoCompleteStyles();
  const translate = useTranslation();
  const { data, loading } = useQuery(getUsersQuery);
  const [addUser] = useMutation(addUserToProjectMutation);

  let allUsers = [];

  if (!loading) {
    allUsers = data.getUsers.filter(
      user => !users.find(projectUser => projectUser._id === user._id),
    );
  }

  const addUserToProject = user => {
    if (user) {
      addUser({
        variables: {
          userId: user._id,
          project: projectName,
        },
        refetchQueries: () => [
          { query: getProjectByNameQuery, variables: { projectName } },
        ],
      });
    }
  };

  return (
    <div className={classes.main}>
      <Typography variant="h5" className={classes.title}>
        {translate('users')}
      </Typography>
      {users.length ? null : translate('noUsers')}
      {loading ? (
        <Loading />
      ) : (
        <Autocomplete
          id="user-list"
          options={allUsers}
          getOptionLabel={option => `${option.name} - ${option.email}`}
          onChange={event => {
            const userIndex = parseInt(event.target.dataset.optionIndex, 10);
            addUserToProject(allUsers[userIndex]);
          }}
          defaultValue={users}
          multiple
          disableClearable
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="secondary"
                key={option._id}
                icon={<FaceIcon />}
                label={`${option.name} - ${option.email}`}
                {...getTagProps({ index })}
                onDelete={event => {
                  getTagProps({ index }).onDelete(event);
                  handleDelete(option._id);
                }}
              />
            ))
          }
          classes={autocompleteClasses}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('addUser')}
              variant="outlined"
              fullWidth
            />
          )}
        />
      )}
    </div>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(userShape),
  handleDelete: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
};

UserList.defaultProps = {
  users: [],
};
