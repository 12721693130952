import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Typography from '@material-ui/core/Typography';
import createProjectMutation from '../../../apollo/mutations/createProject.graphql';
import errorMessages from '../../../common/errorMessages';
import useSnackbar from '../../common/Snackbar/useSnackbar';
import useTranslation from '../../common/useTranslation';
import MainSettingsPanel from './MainSettingsPanel';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(3),
  },
  textField: {
    minWidth: theme.spacing(42),
  },
  button: {
    margin: theme.spacing(4),
    marginLeft: 0,
  },
  floatingLabelFocusStyle: {
    color: theme.palette.secondary.light,
  },
}));

export default function CreateProject() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const translate = useTranslation();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [createProject] = useMutation(createProjectMutation);

  const handleInputChange = event => {
    setName(event.target.value);

    if (nameError) {
      setNameError('');
    }
  };

  const formHasErrors = () => {
    let hasErrors = false;
    if (!name) {
      setNameError('insertName');
      hasErrors = true;
    }
    return hasErrors;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (event, closeParent) => {
    event.preventDefault();

    if (formHasErrors(name)) {
      return;
    }

    try {
      await createProject({
        variables: {
          name,
        },
      });
      setName('');
      snackbar('success', translate('projectCreated'));
      closeParent();
    } catch (err) {
      if (err.message.includes(errorMessages.projectExists)) {
        setNameError('projectExists');
      } else {
        snackbar('error', err.message);
      }
    }
  };

  return (
    <MainSettingsPanel
      icon={<LibraryAddIcon />}
      headerText={translate('addProject')}
    >
      {closeParent => (
        <form onSubmit={event => handleSubmit(event, closeParent)}>
          <div className={classes.root}>
            <TextField
              className={classes.textField}
              margin="normal"
              InputLabelProps={{
                style: {
                  color: '#fff5f8',
                },
              }}
              onChange={handleInputChange}
              error={!!nameError}
              helperText={nameError && translate(nameError)}
              label={translate('projectName')}
              name="name"
              value={name}
            />
            <br />
            <Typography variant="subtitle1" color="secondary">
              {translate('rememberFTP')}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={event => handleSubmit(event, closeParent)}
            >
              {translate('add')}
            </Button>
          </div>
        </form>
      )}
    </MainSettingsPanel>
  );
}
