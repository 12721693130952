import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from '@apollo/react-hooks';
import useTranslation from '../common/useTranslation';
import { generateProjectSettingsPath } from '../../common/routes';
import historyShape from '../../common/shapes/historyShape';
import processProjectMutation from '../../apollo/mutations/processProject.graphql';
import useSnackbar from '../common/Snackbar/useSnackbar';

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.primary.dark,
  },
}));

function ProjectsListMenu({ projectName, history, setImportingFiles }) {
  const translate = useTranslation();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [processProject] = useMutation(processProjectMutation, {
    onError: error => {
      snackbar('error', error.message);
      setImportingFiles(false);
    },
    onCompleted: result => {
      if (result.processProject.code === 200) {
        snackbar('success', translate('importSuccessful'));
      }
      setImportingFiles(false);
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const importFiles = async () => {
    setImportingFiles(true);
    await processProject({
      variables: {
        projectName,
      },
    });
    closeMenu();
  };

  const goToPermissions = () =>
    history.push(generateProjectSettingsPath(projectName));

  return (
    <div>
      <IconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={openMenu}
        className={classes.button}
        variant="contained"
        color="secondary"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        <MenuItem className={classes.menuItem} onClick={importFiles}>
          {translate('importFiles')}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={goToPermissions}>
          {translate('settings')}
        </MenuItem>
      </Menu>
    </div>
  );
}

ProjectsListMenu.propTypes = {
  history: historyShape.isRequired,
  projectName: PropTypes.string.isRequired,
  setImportingFiles: PropTypes.func.isRequired,
};

export default withRouter(ProjectsListMenu);
