import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Lock from '@material-ui/icons/Lock';
import useTranslation from '../../common/useTranslation';
import PasswordConfirmForm from '../../Password/PasswordConfirmForm';
import setPasswordMutation from '../../../apollo/mutations/setPassword.graphql';
import useSnackbar from '../../common/Snackbar/useSnackbar';
import MainSettingsPanel from './MainSettingsPanel';

export default function ChangePassword() {
  const translate = useTranslation();
  const snackbar = useSnackbar();
  const [password, setPasswordState] = useState('');
  const [passwordConfirmation, setPasswordConfirmationState] = useState('');

  const [setPassword, { error: setPasswordError }] = useMutation(
    setPasswordMutation,
    {
      variables: {
        password,
      },
    },
  );

  const changePassword = async closeParent => {
    await setPassword();
    if (!setPasswordError) {
      snackbar('success', translate('setPasswordSuccess'));
      closeParent();
    } else {
      snackbar('error', translate(setPasswordError.message));
    }
  };

  return (
    <MainSettingsPanel icon={<Lock />} headerText={translate('changePassword')}>
      {closeParent => {
        return (
          <PasswordConfirmForm
            password={password}
            passwordConfirmation={passwordConfirmation}
            setPasswordConfirmation={setPasswordConfirmationState}
            onSubmit={() => changePassword(closeParent)}
            setPassword={setPasswordState}
          />
        );
      }}
    </MainSettingsPanel>
  );
}
