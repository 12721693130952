import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HomeIcon from '@material-ui/icons/Home';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Link from '@material-ui/core/Link';
import historyShape from '../../common/shapes/historyShape';
import { generateProjectPath } from '../../common/routes';
import useTranslation from './useTranslation';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: theme.spacing(1, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  crumb: {
    display: 'flex',
    color: theme.palette.common.white,
    cursor: 'default',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function PathBreadcrumbs({
  path,
  projectName,
  displayBack,
  fileId,
}) {
  const classes = useStyles();
  const translate = useTranslation();
  let crumbs = [];
  if (path) {
    crumbs = path.split(',');
    crumbs.splice(0, 1);
    crumbs.splice(crumbs.length - 1, 1);
  }

  return (
    <Breadcrumbs maxItems={5} separator="/" aria-label="breadcrumb">
      {displayBack && (
        <Link
          href={`/#${generateProjectPath(projectName)}?path=${path}${
            fileId ? `&scrollTo=${fileId}` : ''
          }`}
          className={classes.crumb}
          style={{ cursor: 'pointer' }}
        >
          <ChevronLeftIcon /> {translate('goBack')}
        </Link>
      )}
      <div className={classes.crumb}>
        <HomeIcon className={classes.icon} />
        {projectName}
      </div>
      {crumbs.map(crumb => (
        <div className={classes.crumb} key={crumb}>
          {crumb}
        </div>
      ))}
    </Breadcrumbs>
  );
}

PathBreadcrumbs.propTypes = {
  path: PropTypes.string,
  projectName: PropTypes.string.isRequired,
  displaySort: PropTypes.bool,
  history: historyShape,
  displayBack: PropTypes.bool,
  fileId: PropTypes.string,
};

PathBreadcrumbs.defaultProps = {
  path: null,
  displaySort: false,
  history: null,
  displayBack: false,
  fileId: null,
};
