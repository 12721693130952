import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  header: {
    margin: theme.spacing(1, 0),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}));

export default function MainHeader({ text = '' }) {
  const classes = useStyles();
  return (
    <Typography variant="h4" noWrap className={classes.header}>
      {text.toUpperCase()}
    </Typography>
  );
}

MainHeader.propTypes = {
  text: PropTypes.string.isRequired,
};
