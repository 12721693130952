import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import useTranslation from '../common/useTranslation';
import ProjectsListSingleRow from './ProjectsListSingleRow';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  noProjects: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

function ProjectsList({ projects }) {
  const classes = useStyles();
  const translate = useTranslation();
  const isLastChild = index => index === projects.length - 1;

  return (
    <Container maxWidth="md">
      {projects.length ? (
        <List className={classes.root}>
          {projects.map((project, index) => (
            <ProjectsListSingleRow
              key={project._id}
              project={project}
              isLastChild={isLastChild(index)}
            />
          ))}
        </List>
      ) : (
        <div className={classes.noProjects}>{translate('noProjects')}</div>
      )}
    </Container>
  );
}

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ProjectsList;
