import React from 'react';
import CreateUser from './common/CreateUser';
import CreateProject from './common/CreateProject';

export default function AdminSettings() {
  return (
    <>
      <CreateUser />
      <CreateProject />
    </>
  );
}
