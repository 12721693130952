import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '64px',
    height: '8px',
    margin: `0 auto`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}));

export default function MainDivider() {
  const classes = useStyles();

  return <Divider className={classes.divider} />;
}
