import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import createUserMutation from '../../../apollo/mutations/createUser.graphql';
import errorMessages from '../../../common/errorMessages';
import validateEmail from '../../../common/utils/validateEmail';
import useSnackbar from '../../common/Snackbar/useSnackbar';
import useTranslation from '../../common/useTranslation';
import { languages } from '../../../common/constans';
import MainSettingsPanel from './MainSettingsPanel';

const fieldsWidth = 42;

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(3),
  },
  textField: {
    minWidth: theme.spacing(fieldsWidth),
  },
  button: {
    margin: theme.spacing(4),
    marginLeft: 0,
  },
  floatingLabelFocusStyle: {
    color: `${theme.palette.text.secondary} !important`,
  },
  select: {
    margin: `${theme.spacing(3)}px ${0}`,
    minWidth: theme.spacing(fieldsWidth),
  },
  selectItem: {
    color: theme.palette.primary.main,
  },
}));

export default function CreateUser() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const translate = useTranslation();
  const defaultFormState = { email: '', name: '' };

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formState, setFormState] = useState(defaultFormState);
  const [language, setLanguage] = useState(languages.POLISH);

  const [createUser] = useMutation(createUserMutation);

  const handleTextChange = event => {
    const { value, name } = event.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));

    if (nameError && name === 'name') {
      setNameError('');
    }

    if (emailError && name === 'email') {
      setEmailError('');
    }
  };

  const handleSelectChange = event => {
    setLanguage(event.target.value);
  };

  const formHasErrors = (email, name) => {
    let hasErrors = false;
    if (!email || !validateEmail(email)) {
      setEmailError('insertCorrectEmail');
      hasErrors = true;
    }
    if (!name) {
      setNameError('insertUserName');
      hasErrors = true;
    }
    return hasErrors;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (event, closeParent) => {
    event.preventDefault();
    const { email, name } = formState;

    if (formHasErrors(email, name)) {
      return;
    }

    try {
      await createUser({
        variables: {
          email,
          name,
          language,
        },
      });
      setFormState(defaultFormState);
      snackbar('success', translate('instructionsSent'));
      closeParent();
    } catch (err) {
      if (err.message === errorMessages.emailInUse) {
        setEmailError('emailInUse');
      } else {
        snackbar('error', err.message);
      }
    }
  };

  const defaultTextFieldProps = {
    className: classes.textField,
    margin: 'normal',
    onChange: handleTextChange,
    InputLabelProps: {
      style: {
        color: '#fff5f8c',
      },
      classes: {
        focused: classes.floatingLabelFocusStyle,
      },
    },
  };

  return (
    <MainSettingsPanel
      icon={<PersonAddIcon />}
      headerText={translate('addUser')}
    >
      {closeParent => (
        <form onSubmit={event => handleSubmit(event, closeParent)}>
          <div className={classes.root}>
            <TextField
              {...defaultTextFieldProps}
              error={!!nameError}
              helperText={nameError && translate(nameError)}
              label={translate('userName')}
              name="name"
              value={formState.name}
            />
            <br />
            <TextField
              {...defaultTextFieldProps}
              error={!!emailError}
              helperText={emailError && translate(emailError)}
              label="Email"
              name="email"
              value={formState.email}
            />
            <br />
            <FormControl className={classes.select}>
              <InputLabel
                className={classes.floatingLabelFocusStyle}
                id="demo-simple-select-label"
              >
                {translate('language')}
              </InputLabel>
              <Select
                id="language"
                value={language}
                onChange={handleSelectChange}
              >
                {Object.entries(languages).map(arr => (
                  <MenuItem
                    className={classes.selectItem}
                    key={arr[0]}
                    value={arr[1]}
                  >
                    {arr[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={event => handleSubmit(event, closeParent)}
            >
              {translate('add')}
            </Button>
          </div>
        </form>
      )}
    </MainSettingsPanel>
  );
}
