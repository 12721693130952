import isNull from 'lodash/isNull';

const getParentTreeIds = (path, foldersList) => {
  const pathArray = path.split(',');
  pathArray.shift();
  pathArray.pop();

  const ids = [];

  pathArray.forEach((pathElement, index) => {
    const currentPath =
      index === 0 ? null : `,${pathArray.slice(0, index).join(',')},`;

    const element = foldersList.find(
      folder => folder.path === currentPath && folder.name === pathElement,
    );

    ids.push(element._id);
  });

  return ids;
};

const getLastNodePath = node => {
  if (!node) {
    return {};
  }
  if (node.children.length) {
    return getLastNodePath(node.children[0]);
  }
  return { latestElementPath: node.path, latestNodeName: node.name };
};

const createFoldersTree = (foldersList, initialPath) => {
  const baseLevel = foldersList
    .filter(folder => isNull(folder.path))
    .map(baseElement => ({
      _id: baseElement._id,
      name: baseElement.name,
      path: baseElement.path,
    }));

  const getChildren = node => {
    const currentChildren = foldersList.filter(folder => {
      const childrenPath = node.path
        ? `${node.path}${node.name},`
        : `,${node.name},`;

      return folder.path === childrenPath;
    });
    return currentChildren.map(child => ({
      _id: child._id,
      name: child.name,
      path: child.path,
      children: getChildren(child),
    }));
  };

  const tree = baseLevel.map(node => ({
    ...node,
    children: getChildren(node),
  }));

  let latestElementPath;
  let latestNodeName;

  if (initialPath) {
    const splitted = initialPath.split(',');
    splitted.pop();
    latestNodeName = splitted[splitted.length - 1];
    splitted.pop();
    latestElementPath = `${splitted.join(',')},`;
  } else {
    ({ latestElementPath, latestNodeName } = getLastNodePath(tree[0]));
  }

  const defaultExpanded = latestElementPath
    ? getParentTreeIds(latestElementPath, foldersList)
    : [];

  return {
    children: tree,
    defaultExpanded,
    latestElementPath,
    latestNodeName,
  };
};

export default createFoldersTree;
