import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import React, { Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import MainDivider from '../../common/MainDivider';
import MainHeader from '../../common/MainHeader';
import useTranslation from '../../common/useTranslation';
import getProjectByNameQuery from '../../../apollo/queries/getProjectByName.graphql';
import UserList from '../UserList';
import removeProjectUserMutation from '../../../apollo/mutations/removeProjectUser.graphql';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
  },
  root: {
    backgroundColor: theme.palette.primary.dark,
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    padding: 15,
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    minHeight: '60rem',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Settings({ match }) {
  const classes = useStyles();
  const translate = useTranslation();
  const { projectName } = match.params;
  const { data, loading } = useQuery(getProjectByNameQuery, {
    variables: {
      projectName,
    },
  });
  const [removeProjectUser] = useMutation(removeProjectUserMutation, {
    refetchQueries: () => [
      { query: getProjectByNameQuery, variables: { projectName } },
    ],
  });

  const deleteUser = async userId => {
    await removeProjectUser({ variables: { projectName, userId } });
  };

  return (
    <div className={classes.root}>
      {loading ? null : (
        <Fragment>
          <MainHeader text={`${translate('settings')} - ${projectName}`} />
          <MainDivider />

          <Container maxWidth="md">
            <Paper className={classes.paper}>
              <UserList
                handleDelete={deleteUser}
                users={data.getProjectByName.userObjects}
                projectName={projectName}
              />
            </Paper>
          </Container>
        </Fragment>
      )}
    </div>
  );
}

Settings.propTypes = {
  match: PropTypes.object.isRequired,
};
