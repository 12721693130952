import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import useTranslation from '../common/useTranslation';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(3),
    '& label.Mui-focused': {
      color: theme.palette.common.white,
    },
    '& label.Mui-disabled': {
      color: theme.palette.secondary.light,
    },
  },
}));

export default function PasswordConfirmForm({
  password,
  passwordConfirmation,
  setPassword,
  setPasswordConfirmation,
  onSubmit,
}) {
  const translate = useTranslation();
  const classes = useStyles();
  const [inputError, setInputError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const verifyButtonState = (name, value) => {
    const otherValue = name === 'password' ? passwordConfirmation : password;
    const empty = !password || !passwordConfirmation;
    const dontMatch = value !== otherValue;
    setButtonDisabled(empty || dontMatch);
  };

  const checkPasswordMatch = () => {
    if (password !== passwordConfirmation) {
      setInputError(translate('passwordsDontMatch'));
    }
  };

  const getInputProps = inputName => ({
    color: 'secondary',
    type: 'password',
    name: inputName,
    label: translate(inputName),
    placeholder: translate(inputName),
    className: classes.input,
    fullWidth: true,
  });

  return (
    <div>
      <TextField
        {...getInputProps('password')}
        onChange={event => {
          setInputError('');
          setPassword(event.target.value);
          verifyButtonState('password', event.target.value);
        }}
        value={password}
        onBlur={() => (passwordConfirmation ? checkPasswordMatch() : null)}
      />

      <TextField
        {...getInputProps('passwordConfirmation')}
        onChange={event => {
          setInputError('');
          setPasswordConfirmation(event.target.value);
          verifyButtonState('passwordConfirmation', event.target.value);
        }}
        onBlur={checkPasswordMatch}
        value={passwordConfirmation}
        error={!!inputError}
        helperText={inputError}
      />
      <Button
        onClick={onSubmit}
        variant="contained"
        color="secondary"
        fullWidth
        disabled={buttonDisabled}
        className={classes.button}
      >
        {translate('resetPassword')}
      </Button>
    </div>
  );
}

PasswordConfirmForm.propTypes = {
  password: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setPasswordConfirmation: PropTypes.func.isRequired,
};
