import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withRoot from '../withRoot';
import { UserContext } from '../context/UserContext';
import ScrollToTop from './ScrollToTop';
import AppTopBar from './AppTopBar';
import AppFooter from './AppFooter';

const Layout = ({ children }) => (
  <UserContext.Consumer>
    {context => (
      <ScrollToTop>
        <AppTopBar
          language={context.language}
          setLanguage={context.setLanguage}
          loggedIn={context.loggedIn}
          logout={context.logout}
        />
        <Wrapper>{children}</Wrapper>
        <AppFooter />
      </ScrollToTop>
    )}
  </UserContext.Consumer>
);

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  main {
    min-height: 100vh;
    padding: 90px 0px 0px;
  }
`;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
};

export default withRoot(Layout);
