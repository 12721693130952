import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CalendarIcon from '@material-ui/icons/Event';
import PropTypes from 'prop-types';
import fileShape from '../../common/shapes/fileShape';
import historyShape from '../../common/shapes/historyShape';
import { generateFilePath } from '../../common/routes';

export default function FileThumbnail({ file, history, highlight }) {
  const [highlighted, setHighlighted] = useState(highlight);
  useEffect(() => {
    if (highlight && highlighted) {
      setTimeout(() => {
        setHighlighted(false);
      }, 3000);
    }
  });
  const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      borderRadius: 5,
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
      padding: theme.spacing(2),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      display: 'flex',
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    highlighted: {
      backgroundColor: theme.palette.secondary.light,
      transition: theme.transitions.create('background-color'),
    },
    image: {
      minWidth: 150,
      height: 125,
      objectFit: 'contain',
      justifyContent: 'flex-start',
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    date: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.veryLight,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
    meta: {
      paddingTop: theme.spacing(1.5),
    },
  }));

  const classes = useStyles();

  const goToFile = () => {
    history.push(generateFilePath(file.project, file._id));
  };

  return (
    <div
      className={`${classes.root} ${highlighted ? classes.highlighted : ''}`}
      onClick={goToFile}
      id={file._id}
    >
      <img src={file.thumbnail} alt={file.name} className={classes.image} />
      <div className={classes.meta}>
        {file.name}
        <div className={classes.date}>
          <CalendarIcon />
          {moment(file.created.unix, 'x').format('YYYY-MM-DD')}
        </div>
      </div>
    </div>
  );
}

FileThumbnail.propTypes = {
  file: fileShape.isRequired,
  history: historyShape.isRequired,
  highlight: PropTypes.bool,
};

FileThumbnail.defaultProps = {
  highlight: false,
};
