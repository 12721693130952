import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  panel: {
    backgroundColor: theme.palette.primary.main,
    width: '94%',
    verticalAlign: 'middle',
    margin: `${theme.spacing(3.2)}px auto !important`,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: theme.spacing(8),
  },
  avatar: {
    marginRight: theme.spacing(2),
    top: theme.spacing(1.2),
    position: 'absolute',
  },
  summary: {
    minHeight: '64px',
  },
}));

export default function MainSettingsPanel({ icon, headerText, children }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const onExpansionHandler = () => setExpanded(!expanded);

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={onExpansionHandler}
      className={classes.panel}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={classes.summary}
      >
        <Avatar className={classes.avatar}>{icon}</Avatar>
        <div>
          <Typography className={classes.heading}>{headerText}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children(onExpansionHandler)}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

MainSettingsPanel.propTypes = {
  icon: PropTypes.object.isRequired,
  headerText: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
