import sortBy from 'lodash/sortBy';

const sortFiles = (files, sortOrder) => {
  let sortKey = 'created.unix';
  if (['name', 'nameReverse'].includes(sortOrder)) {
    sortKey = 'name';
  }

  if (['latestFirst', 'nameReverse'].includes(sortOrder)) {
    return sortBy(files, sortKey).reverse();
  }

  return sortBy(files, sortKey);
};

export default sortFiles;
