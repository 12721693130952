import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import useTranslation from '../common/useTranslation';
import getUserByToken from '../../apollo/queries/getUserByToken.graphql';
import setPasswordMutation from '../../apollo/mutations/setPassword.graphql';
import errorMessages from '../../common/errorMessages';
import useSnackbar from '../common/Snackbar/useSnackbar';
import routes from '../../common/routes';
import historyShape from '../../common/shapes/historyShape';
import PasswordConfirmForm from './PasswordConfirmForm';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 150,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  hr: {
    width: 55,
    height: 4,
    marginTop: theme.spacing(2),
    display: 'block',
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(3),
    '& label.Mui-focused': {
      color: theme.palette.common.white,
    },
    '& label.Mui-disabled': {
      color: theme.palette.secondary.light,
    },
  },
}));

export default function ResetPassword({ location, history }) {
  const snackbar = useSnackbar();
  const translate = useTranslation();
  const classes = useStyles();
  const { token } = queryString.parse(location.search);

  if (!token) {
    history.push(`/#/${routes.login}`);
  }

  const { data, loading, error } = useQuery(getUserByToken, {
    variables: {
      token,
    },
  });
  const userId = get(data, 'getUserByToken._id', '');

  if (!loading && data && !data.getUserByToken) {
    snackbar('error', translate('tokenInvalid'));
    history.push(`/#/${routes.login}`);
  }

  if (error) {
    if (error.message === errorMessages.tokenInvalid) {
      snackbar('error', translate('tokenInvalid'));
      history.push(`/#${routes.forgotPassword}`);
    } else {
      snackbar('error', error.message);
      history.push(`/#/${routes.login}`);
    }
  }

  const [password, setPasswordState] = useState('');
  const [passwordConfirmation, setPasswordConfirmationState] = useState('');

  const [
    setPassword,
    { data: setPasswordData, error: setPasswordError },
  ] = useMutation(setPasswordMutation, {
    variables: {
      password,
      userId,
      token,
    },
  });

  if (setPasswordError) {
    snackbar('error', setPasswordError.message);
  }
  if (setPasswordData && setPasswordData.setPassword) {
    history.push(`/#/${routes.login}`);
    snackbar('success', translate('setPasswordSuccess'));
  }

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h3" noWrap>
          {translate('resetPassword')}
        </Typography>
        <hr className={classes.hr} />
        {loading ? (
          <CircularProgress color="secondary" data-testid="loading" />
        ) : (
          <PasswordConfirmForm
            onSubmit={async () => setPassword()}
            password={password}
            passwordConfirmation={passwordConfirmation}
            setPassword={setPasswordState}
            setPasswordConfirmation={setPasswordConfirmationState}
          />
        )}
      </Paper>
    </Container>
  );
}

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired,
  history: historyShape.isRequired,
};
