import PropTypes from 'prop-types';

const fileShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
  level: PropTypes.number,
});

export default fileShape;
