const errorMessages = {
  notAuthorized: 'GraphQL error: Not authorized!',
  emailInUse: 'GraphQL error: Email already in use',
  tokenInvalid: 'GraphQL error: Token invalid!',
  noUserFound: 'No user found',
  invalidPassword: 'Invalid password',
  projectExists: 'Project with this name already exists',
};

export default errorMessages;
