import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import useTranslation from '../common/useTranslation';
import resetPasswordMutation from '../../apollo/mutations/resetPassword.graphql';
import useSnackbar from '../common/Snackbar/useSnackbar';
import historyShape from '../../common/shapes/historyShape';
import validateEmail from '../../common/utils/validateEmail';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 150,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  hr: {
    width: 55,
    height: 4,
    marginTop: theme.spacing(2),
    display: 'block',
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
  },
  input: {
    '& label.Mui-focused': {
      color: theme.palette.common.white,
    },
    '& label.Mui-disabled': {
      color: theme.palette.secondary.light,
    },
  },
}));

function ForgotPassword({ history }) {
  const classes = useStyles();
  const translate = useTranslation();
  const snackbar = useSnackbar();

  const [email, setEmail] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [inputError, setInputError] = useState('');

  const [resetPassword] = useMutation(resetPasswordMutation);

  const callResetPassword = async () => {
    if (!email || !validateEmail(email)) {
      setInputError(translate('insertCorrectEmail'));
      return;
    }
    setInProgress(true);
    try {
      const result = await resetPassword({ variables: { email } });
      if (result.data.resetPassword.substring(0, 3) === '250') {
        snackbar('success', translate('passwordResetSuccess'));
        history.push('/login');
      }
    } catch (error) {
      snackbar('error', error.message);
    }
    setInProgress(false);
  };

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h3" noWrap>
          Forgot password
        </Typography>
        <hr className={classes.hr} />
        <TextField
          fullWidth
          label={translate('enterEmailAddress')}
          type="email"
          placeholder={translate('email')}
          name="email"
          onChange={event => {
            setInputError('');
            setEmail(event.target.value);
          }}
          value={email}
          color="secondary"
          disabled={inProgress}
          className={classes.input}
          error={!!inputError}
          helperText={inputError}
          inputProps={{
            'data-testid': 'emailInput',
          }}
        />
        <Button
          onClick={callResetPassword}
          variant="contained"
          color="secondary"
          fullWidth
          className={classes.button}
          disabled={inProgress}
          data-testid="resetButton"
        >
          {inProgress ? (
            <CircularProgress color="secondary" data-testid="loading" />
          ) : (
            translate('resetPassword')
          )}
        </Button>
      </Paper>
    </Container>
  );
}

export default withRouter(ForgotPassword);

ForgotPassword.propTypes = {
  history: historyShape.isRequired,
};
