import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default function Loading({ size }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" size={size} />
    </div>
  );
}

Loading.propTypes = {
  size: PropTypes.number,
};

Loading.defaultProps = {
  size: 40,
};
