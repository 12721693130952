import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  listItem: {
    backgroundColor: theme.palette.primary.light,
  },
  testaaaa: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiList-root': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: 4,
    },
  },
}));

const ContentSort = ({ changeSort, sortOrder }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <Select
        id="content-sort"
        value={sortOrder}
        onChange={event => changeSort(event.target.value)}
        MenuProps={{
          className: classes.testaaaa,
        }}
      >
        <MenuItem className={classes.listItem} value="latestFirst">
          od najnowszych
        </MenuItem>
        <MenuItem className={classes.listItem} value="oldestFirst">
          od najstarszych
        </MenuItem>
        <MenuItem className={classes.listItem} value="name">
          A-Z
        </MenuItem>
        <MenuItem className={classes.listItem} value="nameReverse">
          Z-A
        </MenuItem>
      </Select>
    </FormControl>
  );
};

ContentSort.propTypes = {
  changeSort: PropTypes.func.isRequired,
  sortOrder: PropTypes.string.isRequired,
};

ContentSort.defaultProps = {};

export default ContentSort;
