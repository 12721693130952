import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { languages } from '../../common/constans';
import routes from '../../common/routes';
import historyShape from '../../common/shapes/historyShape';
import AppBar from './AppBar';
import Toolbar, { styles as toolbarStyles } from './Toolbar';
import useTranslation from './useTranslation';
import useSnackbar from './Snackbar/useSnackbar';

const styles = theme => ({
  title: {
    fontSize: 28,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
  },
  left: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 14,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(8),
  },
  linkSecondary: {
    color: theme.palette.secondary.light,
  },
});

function AppTopBar(props) {
  const { history, classes, language, setLanguage, loggedIn, logout } = props;
  const snackbar = useSnackbar();
  const redirect = path => history.push(path);
  const translate = useTranslation();

  const loggedInMenu = [
    <Link
      color="inherit"
      variant="h6"
      underline="none"
      className={classes.rightLink}
      onClick={() => redirect(routes.root)}
      key="projects"
      href={`/#${routes.root}`}
    >
      {translate('projects')}
    </Link>,
    <Link
      color="inherit"
      variant="h6"
      underline="none"
      className={classes.rightLink}
      key="settings"
      href={`/#${routes.settings}`}
    >
      {translate('settings')}
    </Link>,
    <Link
      color="inherit"
      variant="h6"
      underline="none"
      className={classes.rightLink}
      key="logout"
      href={`/#${routes.login}`}
      onClick={() => {
        logout();
        snackbar('success', translate('loggedOut'));
      }}
    >
      {translate('logout')}
    </Link>,
  ];

  const loggedOutMenu = [
    <Link
      color="inherit"
      variant="h6"
      underline="none"
      className={classes.rightLink}
      key="signIn"
      href={`/#${routes.login}`}
    >
      {translate('signIn')}
    </Link>,
  ];

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <Link
              variant="h1"
              underline="none"
              color="inherit"
              className={classes.title}
              href={`/#${routes.root}`}
            >
              {'Fisheye'}
            </Link>
          </div>
          <div className={classes.right}>
            {loggedIn ? loggedInMenu : loggedOutMenu}
            <Languages>
              <LangSwitch
                data-testid={`language-${languages.POLISH}`}
                onClick={() => setLanguage(languages.POLISH)}
                className={language === languages.POLISH ? 'active' : ''}
              >
                PL
              </LangSwitch>
              <Divider>/</Divider>
              <LangSwitch
                data-testid={`language-${languages.ENGLISH}`}
                onClick={() => setLanguage(languages.ENGLISH)}
                className={language === languages.ENGLISH ? 'active' : ''}
              >
                EN
              </LangSwitch>
            </Languages>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppTopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  history: historyShape.isRequired,
  logout: PropTypes.func.isRequired,
};

const Languages = styled.div`
  margin-left: 64px;
  .active {
    color: rgb(33, 64, 101);
  }
`;

const Divider = styled.span`
  color: #fff;
  font-size: 14px;
  margin-left: 8px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  line-height: 1.6;
`;

const LangSwitch = styled.span`
  color: #fff;
  font-size: 14px;
  margin-left: 8px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  line-height: 1.6;
  text-transform: uppercase;
  cursor: pointer;
`;

export default withStyles(styles)(withRouter(AppTopBar));
