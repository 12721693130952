import { dictionary } from './dictionary';

export default class LocalizationHelper {
  constructor(language) {
    this.language = language;
  }

  getText = name => {
    const translation = dictionary.find(field => field.name === name);
    if (translation) {
      return translation[this.language];
    }
    return 'N/A';
  };
}
