import React, { useState } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import HttpsRedirect from 'react-https-redirect';
import apolloClient from './apollo/apolloClient';
import Layout from './components/common/Layout';
import SignIn from './components/SignIn';
import { UserContext } from './components/context/UserContext';
import Snackbar from './components/common/Snackbar/Snackbar';
import SingleFile from './components/Files/SingleFile';
import routes from './common/routes';
import { languages } from './common/constans';
import UserProjectsList from './components/Projects/UserProjectsList';
import ProjectView from './components/Files/ProjectView';
import ProjectSettings from './components/Projects/ProjectSettings/ProjectSettings';
import Settings from './components/Settings/Settings';
import ResetPassword from './components/Password/ResetPassword';
import ForgotPassword from './components/Password/ForgotPassword';

const user = JSON.parse(localStorage.getItem('user'));

export default function App() {
  const [language, setLanguage] = useState(languages.POLISH);
  const [userState, setUserState] = useState(user);

  const logout = () => {
    localStorage.removeItem('user');
    setUserState(null);
  };

  const context = {
    loggedIn: !!userState,
    isAdmin: userState && userState.roles.includes('admin'),
    language,
    setLanguage,
    logout,
    setUserState,
  };

  return (
    <UserContext.Provider value={context}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <HttpsRedirect>
            <Layout>
              <Snackbar>
                <Switch>
                  <Route path={routes.login} component={SignIn} />
                  <Route path={routes.singleFile} component={SingleFile} />
                  <Route
                    path={routes.filePreview}
                    component={props => (
                      <SingleFile {...props} isPreview={true} />
                    )}
                  />
                  <Route path={routes.project} component={ProjectView} />
                  <Route
                    path={routes.projectSettings}
                    component={ProjectSettings}
                  />
                  <Route
                    exact
                    path={routes.root}
                    component={UserProjectsList}
                  />
                  <Route exact path={routes.settings} component={Settings} />
                  <Route
                    exact
                    path={routes.resetPassword}
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path={routes.forgotPassword}
                    component={ForgotPassword}
                  />

                  <Redirect from="*" to="/" />
                </Switch>
              </Snackbar>
            </Layout>
          </HttpsRedirect>
        </Router>
      </ApolloProvider>
    </UserContext.Provider>
  );
}

export { UserContext };
