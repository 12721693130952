export const generateProjectPath = (projectName = ':projectName') =>
  `/project/${projectName}/`;

export const generateDirectoryPath = (
  projectName = ':projectName',
  directoryId = ':directoryId',
) => `/${projectName}/DIRECTORY/${directoryId}`;

export const generateFilePath = (
  projectName = ':projectName',
  fileId = ':fileId',
) => `/${projectName}/FILE/${fileId}`;

export const generateProjectSettingsPath = (projectName = ':projectName') =>
  `/${projectName}/settings`;

export const generateFilePreviewPath = (fileId = ':fileId') =>
  `/filePreview/${fileId}`;

const routes = {
  root: '/',
  login: '/login',
  project: generateProjectPath(),
  singleFile: generateFilePath(),
  filesDirectory: generateDirectoryPath(),
  projectSettings: generateProjectSettingsPath(),
  settings: '/settings',
  resetPassword: '/resetPassword',
  forgotPassword: '/forgotPassword',
  filePreview: generateFilePreviewPath(),
};

export default routes;
