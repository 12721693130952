import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import getUserProjects from '../../apollo/queries/getUserProjects.graphql';
import MainDivider from '../common/MainDivider';
import MainHeader from '../common/MainHeader';
import useSnackbar from '../common/Snackbar/useSnackbar';
import useTranslation from '../common/useTranslation';
import { UserContext } from '../context/UserContext';
import ProjectsList from './ProjectsList';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
  },
  root: {
    backgroundColor: theme.palette.primary.dark,
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    padding: 15,
  },
}));

function UserProjectsList() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const translate = useTranslation();
  const { data, loading, error } = useQuery(getUserProjects, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  });
  const { loggedIn } = useContext(UserContext);

  if (error) {
    if (!loggedIn) {
      return <Redirect to="/login" />;
    }
    if (error.message === 'GraphQL error: Not authorized!') {
      snackbar('error', error.message);
      return <Redirect to="/login" />;
    }
  }

  if (loading) {
    return <CircularProgress />;
  }

  const { getUserProjects: projects } = data;

  return (
    <div className={classes.root}>
      <MainHeader text={translate('projects')} />
      <MainDivider />
      <ProjectsList projects={projects} />
    </div>
  );
}

export default UserProjectsList;
