import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import fetch from 'node-fetch';
import { ErrorLink } from 'apollo-link-error';

const cache = new InMemoryCache();
const linkAuth = new ApolloLink((operation, forward) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user ? user.token : null;
  if (user) {
    operation.setContext({
      headers: {
        userid: user.userid,
        authorization: token,
      },
    });
  }

  return forward(operation);
});
const linkHttp = new HttpLink({
  uri:
    process.env.GRAPHQL === 'local'
      ? '/graphql'
      : `https://fisheye-graphql.programuje.pro/graphql`,
  fetch,
});
const linkError = new ErrorLink(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Not authorized!') {
        localStorage.setItem('user', null);
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([linkAuth, linkError, linkHttp]),
  cache,
});
export default apolloClient;
