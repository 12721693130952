import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import React, { useContext } from 'react';
import MainDivider from '../common/MainDivider';
import MainHeader from '../common/MainHeader';
import useTranslation from '../common/useTranslation';
import { UserContext } from '../context/UserContext';
import AdminSettings from './AdminSettings';
import UserSettings from './UserSettings';
import ChangePassword from './common/ChangePassword';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
  },
  root: {
    backgroundColor: theme.palette.primary.dark,
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    padding: 15,
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    minHeight: '60rem',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const translate = useTranslation();
  const { isAdmin } = useContext(UserContext);

  return (
    <div className={classes.root}>
      <MainHeader text={translate('settings')} />
      <MainDivider />

      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          {isAdmin ? <AdminSettings /> : <UserSettings />}
          <ChangePassword />
        </Paper>
      </Container>
    </div>
  );
}
