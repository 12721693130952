import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, withRouter } from 'react-router-dom';
import ReactJWPlayer from 'react-jw-player';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import getFileQuery from '../../apollo/queries/getFile.graphql';
import historyShape from '../../common/shapes/historyShape';
import errorMessages from '../../common/errorMessages';
import PathBreadcrumbs from '../common/PathBreadcrumbs';
import SearchBar from '../common/SearchBar';
import useTranslation from '../common/useTranslation';
import useSnackbar from '../common/Snackbar/useSnackbar';
import validateEmail from '../../common/utils/validateEmail';
import {
  generateFilePreviewPath,
  generateProjectPath,
} from '../../common/routes';
import Loading from '../../common/Loading';
import sendPreviewUrlMutation from '../../apollo/mutations/sendPreviewUrl.graphql';
import PrevNextFile from './PrevNextFile';
import singleFileStyles from './singleFileStyles';

function SingleFile({ history, isPreview }) {
  const translate = useTranslation();
  const classes = singleFileStyles();
  const snackbar = useSnackbar();
  const [email, setEmailState] = useState('');
  const [inProgress, setInProgressState] = useState(false);
  const { projectName, fileId } = useParams();
  const { data, loading, error } = useQuery(getFileQuery, {
    variables: {
      fileId,
    },
    fetchPolicy: 'network-only',
  });
  const [sendPreviewUrl] = useMutation(sendPreviewUrlMutation);

  if (error) {
    if (error.message === errorMessages.notAuthorized) {
      return 'Error: you are not authorized to see this project';
    }
    return 'An error has occured';
  }
  const copyLink = url => {
    navigator.clipboard.writeText(url).then(() => {
      snackbar('success', translate('savedToClipboard'));
    });
  };

  const sendLink = async url => {
    if (!validateEmail(email)) {
      snackbar('error', translate('insertCorrectEmail'));
      return;
    }
    setInProgressState(true);
    try {
      const result = await sendPreviewUrl({
        variables: {
          email,
          previewUrl: url,
          projectName,
        },
      });
      if (result.data.sendPreviewUrl.substring(0, 3) === '250') {
        snackbar('success', translate('emailSent'));
        setEmailState('');
      }
    } catch (e) {
      snackbar('error', e.message);
    }
    setInProgressState(false);
  };

  const setEmail = event => {
    setEmailState(event.target.value);
  };

  if (loading) {
    return <Loading />;
  }
  const { getFile: file } = data;
  const previewUrl = `${window.location.origin}/#${generateFilePreviewPath(
    file._id,
  )}`;

  const handleSearch = query => {
    history.push(`${generateProjectPath(projectName)}?search=${query}&page=1`);
  };

  return (
    <Fragment>
      {!isPreview && <SearchBar handleSearch={handleSearch} />}
      <Container>
        {!isPreview && (
          <div className={classes.topBar}>
            <PathBreadcrumbs
              path={file.path}
              history={history}
              projectName={projectName}
              displayBack
              fileId={file._id}
            />
          </div>
        )}
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <ReactJWPlayer
                playerId={file._id}
                playerScript={process.env.REACT_APP_PLAYER_SCRIPT}
                file={file.url}
                image={file.thumbnail}
              />
            </Paper>
            {!isPreview && (
              <PrevNextFile
                currentFileId={file._id}
                projectName={projectName}
                history={history}
              />
            )}
          </Grid>
          <Grid item xs={4} className={classes.column}>
            <Paper className={classes.meta}>
              <Typography variant="h5" noWrap>
                {file.name}
              </Typography>
            </Paper>
            {!isPreview && (
              <Fragment>
                <div className={classes.fileLink}>
                  <div className={classes.container}>
                    {' '}
                    <span className={classes.fileLinkTitle}>
                      {translate('previewLink')}
                    </span>
                    <div className={classes.inputWrapper}>
                      <InputBase
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        id="link"
                        value={previewUrl}
                        inputProps={{ 'aria-label': 'search' }}
                      />
                      <div
                        className={classes.linkButton}
                        onClick={() => copyLink(previewUrl)}
                      >
                        <FileCopyIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.fileLink}>
                  <div className={classes.container}>
                    <span className={classes.fileLinkTitle}>
                      {translate('sendLink')}
                    </span>
                    <div className={classes.inputWrapper}>
                      <InputBase
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        id="link"
                        placeholder={translate('enterEmailAddress')}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={setEmail}
                        value={email}
                        type="email"
                      />
                      {inProgress ? (
                        <div className={classes.linkButton}>
                          <Loading size={22} />
                        </div>
                      ) : (
                        <div
                          className={classes.linkButton}
                          onClick={() => sendLink(previewUrl)}
                        >
                          <SendIcon />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

SingleFile.propTypes = {
  history: historyShape.isRequired,
  isPreview: PropTypes.bool,
};

SingleFile.defaultProps = {
  isPreview: false,
};

export default withRouter(SingleFile);
